import React, { FC, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { captureException } from '@sentry/browser';
import SUDealCoupon, { DealCouponLayoutType } from 'shared-ui/src/components/molecules/DealCoupon';
import { isMultiDeal } from 'shared-ui/src/utils/promos';
import { Deal, ProductDealResponse } from 'shared-ui/src/types/deal';
import useFetch from 'shared-ui/src/hooks/useFetch';
import { PageRegion } from 'datalayer-service/src/types/enums';
import { COLLECTION, MANUFACTURER, MATTRESS_COLLECTION } from 'shared-ui/src/constants/commons';

export const BEST_DEALS_SIDEBAR_ID = 'best-deals-sidebar';

const DEALS_ENDPOINT = '/promos/deals-stores/';

interface BestDealsSidebarProps {
  pageRegion: PageRegion;
  productSlug: string;
  productClassname: string;
  productName: string;
  mobile: boolean;
  isCMSPage?: boolean;
  onlyOnlineDeals?: boolean;
  isDiscontinued?: boolean;
  onSeeDeal: () => void;
}

const getEndpointProductType = (type: string, isDiscontinued: boolean): string => {
  if (isDiscontinued) return MANUFACTURER;

  const productType = type === MATTRESS_COLLECTION ? COLLECTION : type;

  return productType;
};

const BestDealsSidebar: FC<BestDealsSidebarProps> = ({
  pageRegion,
  productSlug,
  productClassname,
  productName,
  mobile,
  onlyOnlineDeals = false,
  isCMSPage = false,
  isDiscontinued = false,
  onSeeDeal,
}) => {
  const { data, error } = useFetch<ProductDealResponse>(
    `${DEALS_ENDPOINT}/${getEndpointProductType(productClassname, isDiscontinued)}/${productSlug}/`,
  );
  const [formattedDeals, setFormattedDeals] = useState<Deal[] | string[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (data && data.deals && data.deals.length > 0) {
      const items: Deal[] = [];

      if (data.brand) {
        if (isMultiDeal(data)) {
          data.deals.forEach((singleDeal) => {
            if (data.brand) items.push({ brand: data.brand, deals: [singleDeal] });
          });
        } else {
          items.push(data as Deal);
        }
      }

      setIsFetching(false);
      setFormattedDeals(onlyOnlineDeals ? [items[0]] : items);
    } else setFormattedDeals(['', '', '']);

    if (error) {
      const msg = `Error fetching ${productName} deals`;
      console.error(msg, error);
      captureException(new Error(`${msg} -> ${error}`));
    }
  }, [data, error]);

  const title = onlyOnlineDeals ? 'Best Online Deal' : `Best Deals on ${productName}`;

  const getUpdateLocationAnchor = (text: string) => (
    <a
      className="best-deals-update-zipcode-anchor"
      data-toggle="modal"
      data-target={`#${mobile ? 'mobileUpdateLocation' : 'updateLocation'}`}
    >
      {text}
    </a>
  );

  const userLocation = window.userData?.session?.location;
  const userCity = userLocation?.zip_code?.city;
  const userCityAbbr = userLocation?.zip_code?.state_abbr;

  return (
    <div className={`best-deals-container ${isCMSPage ? 'cms-page' : ''}`}>
      {!isDiscontinued && <h3 className="best-deals-title">{title}</h3>}
      {!onlyOnlineDeals ? (
        <div className="best-deals-update-location">
          {userLocation ? (
            <>
              Showing results for{' '}
              <b>
                {userCity}, {userCityAbbr}
              </b>{' '}
              {getUpdateLocationAnchor('(change location)')}
            </>
          ) : (
            <>Enter your location to show recommendations {getUpdateLocationAnchor('(enter your zipcode)')}</>
          )}
        </div>
      ) : null}

      <ul>
        {formattedDeals.map((deal, idx) => {
          const key = isFetching ? `best-deal-loading-${idx}` : `${deal.brand.slug}-${deal.deals[0].retailer.slug}`;

          return (
            <li key={key}>
              <SUDealCoupon
                pageRegion={pageRegion}
                // @ts-ignore
                user={window.userData}
                deal={isFetching ? null : deal}
                layout={mobile ? DealCouponLayoutType.HORIZONTAL : DealCouponLayoutType.VERTICAL}
                isFetching={isFetching}
                onSeeDeal={onSeeDeal}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BestDealsSidebar;

export function renderBestDealsSidebar(onSeeDeal) {
  const elements = document.querySelectorAll(`[id=${BEST_DEALS_SIDEBAR_ID}]`);

  elements.forEach((element) => {
    if (element) {
      const pageRegion = element.getAttribute('data-page-region');
      const productSlug = element.getAttribute('data-product-slug');
      const productClassname = element.getAttribute('data-product-classname');
      const productName = element.getAttribute('data-product-name');
      const mobile = element.getAttribute('data-mobile');
      const isCMSPage = element.getAttribute('data-is-cms') === 'true';
      const onlyOnlineDeals = element.getAttribute('data-only-online-deals') === 'true';
      const isDiscontinued = element.getAttribute('data-discontinued-product') === 'true';
      const root = createRoot(element);

      if (productSlug && productClassname && productName) {
        root.render(
          <BestDealsSidebar
            pageRegion={(pageRegion as PageRegion) || PageRegion.SHOP_ONLINE}
            productSlug={productSlug}
            productClassname={productClassname}
            productName={productName}
            mobile={mobile === 'True'}
            isCMSPage={isCMSPage}
            onlyOnlineDeals={onlyOnlineDeals}
            isDiscontinued={isDiscontinued}
            onSeeDeal={onSeeDeal}
          />,
        );
      }
    }
  });
}
