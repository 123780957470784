import 'bootstrap/js/src/popover';
import 'bootstrap/js/src/alert';

import 'jquery-toggles/css/toggles.css';
import 'jquery-toggles/css/themes/toggles-light.css';

import 'select2';
import 'jquery-colorbox';
import 'bootstrap-3-typeahead/bootstrap3-typeahead';

import { getSuccessModalData } from 'shared-ui/src/hooks/useSaveDealSuccessModal';

import '../static/major/scss/extra.scss';
import 'shared-ui/src/global.scss';
import '../static/major/css/pretty-photo.css';
import '../static/js/colorbox/colorbox.css';
import '../static/js/autocomplete/jquery.autocomplete.css';
import '../static/js/tooltip/jquery.tooltip.css';
import '../static/js/stars/jquery.rating.css';

import '../static/major/js/valign-imgs';
import '../static/major/js/osqa-messages';
import '../static/major/js/glossary';
import '../static/major/js/generaleffects';
import '../static/js/autocomplete/jquery.autocomplete';
import './account/social_login';
import './match-score/match-score-lazy-load';

import * as Sentry from '@sentry/browser';
import { getDealFromUrlParams, openCashbackOutboundUrl } from 'shared-ui/src/utils/promos';
import { goToCashback } from 'shared-ui/src/components/molecules/DealCoupon';
import { PromotionType } from 'shared-ui/src/types/promotion';
import enableShowCodeButton from './promo/promo-code';
import {
  trackFullReviewCTA,
  trackGetDirectionsCTA,
  trackMiscActions,
  trackQuizCTAClickFrom,
  trackShopBedCTA,
  trackShopStoreCTA,
  trackTakeQuizCTA,
  trackSaveExtraCTA,
} from './analytics/cta';
import trackLDPMetrics from './analytics/ldp-metrics';
import { trackSearchEvent, trackSearchSubmitEvent } from './analytics/search';
import { initializePopovers, initializeRebatePopover, setupClosePopoverOnClickOutside } from './popovers/popovers';
import initPromoButton from './react-components/promo/buttons/sale';
import promoBoxTrigger from './react-components/promo/modals/online-promo-modal';
import { renderProductDiscountBox } from './react-components/ProductDiscountBox';
import { renderBrandPromoBox } from './react-components/BrandPromoBox';
import { renderPromoModal } from './react-components/PromoModal';
import { ServiceWorkerSetup } from '../static/push/sw-setup';
import { setCookie } from './utils';
import { renderPromosToaster } from './react-components/PromosToaster';
import { renderCompareWidget } from './react-components/CompareWidget';
import { renderBestDeal } from './react-components/BestDeal';
import { renderReviewSummary } from './react-components/ReviewSummary';
import { BEST_DEALS_SIDEBAR_ID, renderBestDealsSidebar } from './react-components/BestDealsSidebar';
import handleDealCouponSeeDeal from './promo/see-deal';

const PACKAGE_VERSION = require('../package.json').version;

Sentry.init({
  // eslint-disable-next-line
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: PACKAGE_VERSION,
  sampleRate: 0.5,
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.15 : 1,
  integrations: [Sentry.browserTracingIntegration()],
  debug: process.env.NODE_ENV !== 'production',
  autoSessionTracking: false,
  beforeSend(event, hint) {
    const error = hint.originalException;

    if (error && error.stack && error.stack.indexOf('tag/js/gpt.js') !== -1) {
      // Ignore errors from Google Optimize/Google Tag Services
      return null;
    }

    return event;
  },
});

const handleCheckDiscountsClick = (
  manufacturerID,
  mainPromotionType,
  rebatePromotionID,
  onlinePromotionOutboundURL,
  bestDealHash,
  totalDeals,
  isMobile = false,
) => {
  if (totalDeals === 1) {
    const rebatePromotion = rebatePromotionID ? { id: rebatePromotionID } : undefined;

    if (mainPromotionType === PromotionType.AFFILIATE_REBATE) {
      const onlinePromotion = onlinePromotionOutboundURL ? { outbound_url: onlinePromotionOutboundURL } : undefined;

      if (manufacturerID && rebatePromotion && onlinePromotionOutboundURL) {
        openCashbackOutboundUrl(manufacturerID, rebatePromotion, onlinePromotion);
      }
    } else if (mainPromotionType === PromotionType.LOCAL_REBATE && manufacturerID && rebatePromotion) {
      goToCashback(rebatePromotion, { brand: { id: manufacturerID } }, bestDealHash);
    }
  } else {
    const element = document.getElementById(BEST_DEALS_SIDEBAR_ID);

    if (element) {
      const { offsetTop } = element;
      const top = isMobile ? offsetTop - 60 : offsetTop + 100;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  }
};

window.ServiceWorkerSetup = ServiceWorkerSetup;
window.setCookie = setCookie;
window.enableShowCodeButton = enableShowCodeButton;
window.initializeRebatePopover = initializeRebatePopover;
window.initializePopovers = initializePopovers;
window.trackQuizCTAClickFrom = trackQuizCTAClickFrom;
window.trackSearchSubmitEvent = trackSearchSubmitEvent;
window.trackFullReviewCTA = trackFullReviewCTA;
window.trackSaveExtraCTA = trackSaveExtraCTA;
window.handleCheckDiscountsClick = handleCheckDiscountsClick;

$(document).ready(() => {
  const successModalData = getSuccessModalData();
  if (successModalData.show) {
    renderPromoModal(undefined, successModalData);
  }

  const handleGetDeal = (currentDeal) => {
    renderPromoModal(currentDeal);
  };

  getDealFromUrlParams().then((deal) => {
    if (deal) {
      renderPromoModal(deal);
    }
  });

  enableShowCodeButton();
  initializePopovers();
  setupClosePopoverOnClickOutside();
  promoBoxTrigger();
  trackTakeQuizCTA();
  trackShopStoreCTA();
  trackGetDirectionsCTA();
  trackShopBedCTA();
  trackMiscActions();
  trackLDPMetrics();
  trackSearchEvent();
  initPromoButton();
  trackFullReviewCTA();
  trackSaveExtraCTA();
  renderProductDiscountBox(handleGetDeal);
  renderBrandPromoBox(handleGetDeal);
  renderPromosToaster(handleGetDeal);
  renderBestDeal(handleGetDeal);
  renderBestDealsSidebar(handleDealCouponSeeDeal);
  renderCompareWidget();
  renderReviewSummary();
});
