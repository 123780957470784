import React from 'react';
import qs from 'qs';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import {
  CategoryContentType,
  CompareItem,
  CompareType,
  EditorialRating,
  EditorialRatingObjectType,
  EditorialRatingProduct,
  LayerRowHeight,
  MatchedPrice,
} from '../types/mattress-comparison';
import { HIERARCHY_CLASSNAMES_SHORT_MATTRESS, MATTRESS, ROUTES } from '../constants/commons';
import { Price } from '../types/commons';
import { MattressModel } from '../types/mattresses';
import { NewPromotion } from '../types/promotion';

export function getEditorialRatingName(item: EditorialRatingObjectType): string {
  return get(item, 'name', '');
}

export function getEditorialRatingValue(item: EditorialRatingObjectType): number {
  return Math.round(get(item, 'value', 0));
}

export function hasPromotionAvailable(product?: EditorialRatingProduct, price?: Price): boolean | undefined {
  return product && has(price, 'promo') && (!isEmpty(price?.promo) || !isEmpty(product.cashback));
}

export const checkIfHasDiscount = (item: EditorialRating): Price | undefined => {
  // We will use the item price size and not the size code because for some reason
  // they are not same as the options in size-choices.
  if (item.product) {
    return has(item, 'product.prices')
      ? item.product!.prices.find((p) => p.size === item.product!.mattress_size)
      : undefined;
  }

  return undefined;
};

export const getMatchedPrices = (items: EditorialRating[]): MatchedPrice[] => {
  return items.map((item) => ({ matchedPrice: checkIfHasDiscount(item), id: item.id }));
};

export const isRowVisible = (hiddenRows: string[], row: string): boolean => {
  return !hiddenRows.includes(row);
};

export const buildComparisonSearchParams = (value: string, compareType: CompareType = 'brand'): string => {
  const params = { q: value, type: compareType };
  const options = { indices: false, addQueryPrefix: true };

  return qs.stringify(params, options);
};

export const getCompareItemType = (classname: string): CompareType => {
  if (classname === MATTRESS) {
    return 'mod';
  }
  return 'brand';
};

export const mapToCompareItem = (item: EditorialRating | any): CompareItem => {
  // Is EditorialRating.
  if (item.product) {
    const i = item as EditorialRating;

    return {
      slug: i.product?.slug || '',
      name: i.object_name,
      isActive: i.product?.is_active,
      img: i.product?.cover_image?.image || '',
      logo: i.product?.logo || '',
      url: i.product?.outbound_url,
      type: getCompareItemType(i.product?.classname || ''),
      classname: i.product?.classname || '',
      externalDomain: i.product?.external_domain,
    };
  }

  // Is compare search result item.
  return {
    slug: item.slug,
    name: item.name,
    img: item.cover_image?.image || '',
    logo: item.logo,
    isActive: item.is_active,
    type: getCompareItemType(item.classname),
    classname: item.classname,
    externalDomain: item.product?.external_domain,
  };
};

export const formatComparisonSearchOptions = (
  data: any[],
  compareItems: CompareItem[],
): { label: JSX.Element | string; value: string }[] => {
  if (isEmpty(data)) {
    return [
      {
        label: 'Try searching for another model.',
        value: '',
      },
    ];
  }

  const nonDuplicatedOptions = data.filter(({ slug }) => {
    const duplicatedItem = !compareItems.find((item) => item.slug === slug);

    return duplicatedItem;
  });

  return nonDuplicatedOptions.map((i) => {
    const item = mapToCompareItem(i);
    const typeMap: Record<CompareType, string> = {
      brand: 'Brand',
      mod: 'Model',
      '': '',
    };

    let typeText = typeMap.brand;

    if (item.type && Object.keys(typeMap).includes(item.type)) typeText = typeMap[item.type as CompareType];

    const extraText = item.isActive === false ? `Discontinued ${typeText}` : typeText;

    return {
      label: (
        <>
          {item.name} <span>({extraText})</span>
        </>
      ),
      value: item.slug,
    };
  });
};

export const getCompareURL = (items: CompareItem[]): string => {
  const params: { [key: string]: string[] } = {};
  items.forEach((item) => {
    const itemClassname = item.classname || '';

    if (itemClassname) {
      const shortParam =
        HIERARCHY_CLASSNAMES_SHORT_MATTRESS[itemClassname as keyof typeof HIERARCHY_CLASSNAMES_SHORT_MATTRESS];

      if (params[shortParam]) params[shortParam].push(item.slug);
      else params[shortParam] = [item.slug];
    }
  });

  return `${ROUTES.mattressComparison}${qs.stringify(params, { indices: false, addQueryPrefix: true })}`;
};

export const formatEditorialRatingProductToMattressModel = (
  product?: EditorialRatingProduct,
  matchedPrice?: Price,
): MattressModel | undefined => {
  if (!product) return undefined;

  return {
    ...product,
    brand_outbound_url: product.classname === MATTRESS ? product.brand_outbound_url : product.outbound_url,
    name_short: '',
    name_unique: '',
    images: [],
    manufacturer: undefined,
    favorited: false,
    comfort_level: 0,
    surface: 0,
    released: 0,
    extra_data: {
      ...product?.extra_data,
      cashback_promotion: product?.cashback,
      promotion: isEmpty(matchedPrice?.promo) ? undefined : (matchedPrice?.promo as NewPromotion),
    } as unknown,
  } as MattressModel;
};

export const getLayerRowHeight = (items: LayerRowHeight[], id: string): string => {
  const PADDING_BOTTOM = 40;
  const item = items.find((l) => l.layerType.toLowerCase().replace(' ', '-') === id || l.layerType === id);

  return item ? `${item.height + PADDING_BOTTOM}px` : 'auto';
};

export const allRowsFromCategoryAreHidden = (category: CategoryContentType, hiddenRows: string[]): boolean => {
  return category.rows.every((row) => {
    return hiddenRows.includes(row.id);
  });
};
